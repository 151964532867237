import { LabelHTMLAttributes } from 'react'

import styles from './Label.module.css'

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  required?: boolean
}

export function Label(props: LabelProps) {
  const { children, className, ...rest } = props

  return (
    <label className={`${styles.label} ${className ?? ''}`} {...rest}>
      {children}
      {props.required && <span className="text-red-400">*</span>}
    </label>
  )
}
